<template>
  <div class="container w-full mx-auto px-4 md:px-0">
    <div class="md:flex md:space-x-5">
      <div class="md:w-1/3">
        <ViewTitle :title="$t('cabinet.subscriptions.title')" />
        <div v-for="subscription in subscriptions" :key="subscription.id">
          <SiteSnippet :site="subscription.site" />
        </div>

        <div class="text-center py-16" v-if="subscriptions.length <= 0">
          <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
          <p class="text-gray-400">
            {{ $t("cabinet.subscriptions.no_subscriptions") }}
          </p>
        </div>
      </div>
      <div class="md:w-2/3">
        <ViewTitle :title="$t('cabinet.subscriptionsFeedbacks')" />

        <Feedback
          v-for="feedback in feedbacks"
          :key="feedback.id"
          v-bind:feedback="feedback"
        />

        <div class="text-center py-16" v-if="feedbacks.length <= 0">
          <inbox-icon size="4x" class="block m-auto text-gray-300"></inbox-icon>
          <p class="text-gray-400">
            {{ $t("cabinet.noSubscriptionsFeedbacks") }}
          </p>
        </div>

        <button
          type="button"
          v-if="showMoreAvailable"
          @click="getSubscriptionsTimeline"
          class="
            inline-block
            uppercase
            bg-webmoney
            text-white
            px-5
            py-2
            rounded
            hover:bg-blue-400 hover:text-white
            shadow
            text-sm
            my-4
          "
        >
          {{ $t("shared.more_feedbacks") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SiteSnippet from "@/components/cabinet/SiteSnippet";
import subscriptions from "@/api/subscriptions";
import Feedback from "@/components/Feedback.vue";
import { InboxIcon } from "vue-feather-icons";

export default {
  data() {
    return {
      root: process.env.VUE_APP_BACKEND_URL,
      feedbacks: [],
      page: 1,
      showMoreAvailable: true,
    };
  },
  components: {
    SiteSnippet,
    Feedback,
    InboxIcon,
  },
  created() {
    this.getSubscriptionsTimeline();
    this.getUserSubscriptions();
  },
  watch: {
    $route: ["getUserSubscriptions"],
  },
  computed: mapState({
    subscriptions: (state) => state.cabinet.subscriptions.list,
  }),
  methods: {
    getUserSubscriptions() {
      this.$store.dispatch("cabinet/subscriptions/getUserSubscriptions", 1);
    },
    getSubscriptionsTimeline() {
      subscriptions.getSubscriptionsTimeline(this.page).then((data) => {
        this.feedbacks = [...this.feedbacks, ...data.feedbacks];
        this.page++;
        if (data.feedbacks.length < 10) {
          this.showMoreAvailable = false;
        }
      });
    },
  },
};
</script>
